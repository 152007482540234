import './App.css';
import {useEffect, useState} from 'react';
import { getLCP } from 'web-vitals';

function App() {

  const [tarefas, setarTarefas] = useState([

    /*
    {
      id:0,
      tarefa:'Minha Tarefa do Dia',
      finalizada:false
    },
    {
      id:0,
      tarefa:'Minha Tarefa do Dia 2',
      finalizada:true
    }, 
    */

  ]);
  const [modal, setModal] = useState(false);
  
  const salvarTarefa = () => {
    //todo: Salvar a tarefa

    var tarefa = document.getElementById('content-tarefa');

    setarTarefas([
      ...tarefas,
      {
        id: new Date().getTime(),
        tarefa: tarefa.value,
        finalizada:false
      }
    ]);

    window.localStorage.setItem('tarefas',JSON.stringify([
      ...tarefas,
      {
        id: new Date().getTime(),
        tarefa: tarefa.value,
        finalizada:false
      }
    ]));
    setModal(false);

  }
  
  const marcarConcluida = (id,opt) =>{
    let novasTarefas = tarefas.filter(function(val){
        if(val.id == id){
          val.finalizada = opt;
        }
        return val;
    })

    setarTarefas(novasTarefas);
    window.localStorage.setItem('tarefas',JSON.stringify(novasTarefas));
  }
  
  const abrirModal = () => {
    setModal(!modal);
  }

  const deletarTarefa = (id) =>{
    let novasTarefas = tarefas.filter(function(val){
      if(val.id != id){
        return val;
      }
   })

   setarTarefas(novasTarefas);
   window.localStorage.setItem('tarefas',JSON.stringify(novasTarefas));

  }

  useEffect(()=>{
    //Fazer uma chamada para API e preencher o estado Tarefas.

    if(window.localStorage.getItem('tarefas') !=undefined){
      setarTarefas(JSON.parse(window.localStorage.getItem('tarefas')));
      console.log(window.localStorage.getItem('tarefas'));
    }
    
  },[])

  return (
    <div className="App">
        {
          modal?
          <div className="modal">
            <div className="modalcontent">
              <h3>Adicionar Item</h3>
              <input id="content-tarefa" type="text" />
              <button onClick={()=>salvarTarefa()}>Salvar</button>
            </div>
          </div>
          :
          <div></div>
        }
        <div className="logo">LP<span>Code</span></div>
        <div onClick={()=>abrirModal()} className="AddTarefas">+</div>
        <div className="BoxTarefas">
          <h2>Minha Lista</h2>
          {
            tarefas.map((val)=>{
              if(!val.finalizada){
                return (
                  <div className="tarefasingle">
                    <p onClick={()=>marcarConcluida(val.id,true)}>{val.tarefa}</p>
                    <span onClick={()=>deletarTarefa(val.id)} className="Delet">x</span>
                  </div>
                );
              }else{
                return (
                  <div className="tarefasingle">
                      <p onClick={()=>marcarConcluida(val.id,false)} style={{textDecoration:'line-through'}}>{val.tarefa}</p>
                      <span onClick={()=>deletarTarefa(val.id)} className="Delet">x</span>
                  </div>
                );
              }
            })
          }
        </div>


    </div>
  );
}

export default App;
